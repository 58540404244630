@import '@aurora/shared-client/styles/_variables.pcss';

.lia-footer {
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    row-gap: 30px;
  }

  &-details {
    margin-left: 20px;
    max-width: 405px;

    @media (--lia-breakpoint-down-md) {
      margin-left: 10px;
      max-width: 100%;
      font-size: var(--lia-bs-font-size-sm);
    }

    &-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @media (--lia-breakpoint-down-md) {
        flex-direction: column;
      }
    }
  }

  &-description {
    font-size: var(--lia-bs-font-size-sm);
  }
}

.lia-author-login,
.lia-node-title {
  display: block;
  font-weight: var(--lia-bs-font-weight-bold);
  color: var(--lia-bs-body-color);
}

.lia-action {
  margin-left: 40px;
  @media (--lia-breakpoint-down-md) {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.lia-user-rank {
  margin-left: 5px;
}
